import axios, { AxiosError, AxiosResponse } from "axios";

// API URL
axios.defaults.baseURL = '/api';

// Get response body from axios request response
const responseBody = (response) => response.data;

// Managing Requests Responses by providing its data.
const requests = {
	get: (url) => axios.get(url).then(responseBody),
	post: (url, body) => axios.post(url, body).then(responseBody),
	put: (url, body) => axios.put(url, body).then(responseBody),
	del: (url) => axios.delete(url).then(responseBody),
};

const ContactUs = {
    ContactUs: (data) => requests.post("/ContactUs/ContactUs", data),
    Order: (data) => requests.post("/ContactUs/order", data),
}

export default {
    ContactUs
};