import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

import React from "react";

/* Routers */
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
/* Css Files */
import "./Css/animate.css";
import "./Css/bootstrap.css";
// import "./Css/font-awesome.min.css";
import "./style.css";
import "./Css/fonts.css";
import "./Css/responsive.css";
import "./Css/color/color.css";
import "./AWDSMainRU.css";
import "./AWDSMainAR.css";
/* LOGO */
import Logo from "./images/logo.svg";
import FooterLogo from "./images/logo-footer.svg";
/* Pages */
import Main from "./Components/Pages/Main";
// import MainAR from "./Components/Pages/MainAR";
import Contact from "./Components/Pages/Contact";

/* Components */
import Header from "./Components/Header/Header";
// import HeaderAR from "./Components/Header/HeaderAR";
import Footer from "./Components/Footer/Footer";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

function App() {
    const [t, i18n] = useTranslation();
    document.title = t("page.title");
    var selectMinu_ab = document.getElementById("SelectLang-absolute");
    const [lang, setLang] = useState();
    useEffect(() => {
        if (!window.localStorage.getItem("Display_Lang")) {
            window.localStorage.setItem("Display_Lang", "en");
            document.body.removeAttribute("ïd");
            // let select = document.getElementById("SelectLang");
            selectMinu_ab.value = "en";
        } else {
            let select = document.getElementById("SelectLang-absolute");
            setLang(window.localStorage.getItem("Display_Lang"));
            select.value = window.localStorage.getItem("Display_Lang");
            i18n.changeLanguage(select.value);
            if (window.localStorage.getItem("Display_Lang") === "ar") {
                document.body.setAttribute("id", "ar");
            }
            if (window.localStorage.getItem("Display_Lang") === "ru") {
                document.body.setAttribute("id", "ru");
            }
        }
        const form = document.querySelector("#form");
        /* Check href contains "/contact" */
        window.addEventListener("load", () => {
            if (window.location.href.indexOf("/order") > -1) {
                form.style = `display: none;`;
            } else {
                form.style = `display: inline-block;`;
            }
        });
        
    }, []);
    return (
        <div className="App">
            <div id="pre-loader" className="loader-container">
                <div className="loader">
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div id="wrapper">
                <Header Logo={Logo} />
                {/* <HeaderAR Logo={Logo} /> */}
                <Router>
                    <Routes>
                        <Route path="/" element={<Main />} />
                        <Route path="/order" element={<Contact />} />
                    </Routes>
                </Router>
                {/* <Main /> */}
                {/* <MainAR /> */}
                <Footer Footer_Logo={FooterLogo} />
                <Router>

             

                <span id="back-top"
                    onClick={()=>{
                        window.open("https://wa.me/971506225702", "_blank")
                    }}
                >
                    {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                    >
                        <path d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z" />
                    </svg> */}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 138.043 137.968"><g transform="translate(-76.299 -317.319)"><path d="M117.366,52.012A68.43,68.43,0,0,0,9.708,134.56L0,169.987l36.268-9.522a68.2,68.2,0,0,0,32.694,8.321h.032A67.967,67.967,0,0,0,117.366,52.012ZM68.994,157.27a56.756,56.756,0,0,1-28.967-7.918l-2.064-1.233-21.5,5.641L22.2,132.773l-1.356-2.158a56.966,56.966,0,1,1,105.633-30.228A57.492,57.492,0,0,1,68.994,157.27Zm31.181-42.585c-1.7-.862-10.107-4.992-11.677-5.546s-2.712-.862-3.854.862-4.414,5.549-5.42,6.734-2,1.293-3.7.431c-10.044-5.024-16.638-8.965-23.263-20.32-1.752-3.02,1.752-2.8,5.024-9.337a3.153,3.153,0,0,0-.161-3.006c-.431-.862-3.854-9.274-5.255-12.7-1.4-3.328-2.8-2.866-3.854-2.929-.984-.063-2.127-.063-3.265-.063A6.331,6.331,0,0,0,40.2,70.941,19.206,19.206,0,0,0,34.2,85.2a33.486,33.486,0,0,0,6.965,17.689c.862,1.139,12.048,18.4,29.212,25.82C81.242,133.4,85.481,133.8,90.9,133c3.3-.494,10.107-4.131,11.523-8.135a14.294,14.294,0,0,0,.984-8.135c-.41-.778-1.542-1.209-3.234-2.043Z" transform="translate(76.299 285.299)" fill="#c5a47e"/></g></svg>
                </span>
        



                </Router>
                
                
                <select
                    id="SelectLang-absolute"
                    className="LangSelect-absolute"
                    onChange={() => {
                        switch (selectMinu_ab.value) {
                            default:
                                i18n.changeLanguage("en");
                                document.body.removeAttribute("id");
                                localStorage.setItem(
                                    "Display_Lang",
                                    selectMinu_ab.value
                                );

                                break;
                            case "ar":
                                i18n.changeLanguage("ar");
                                document.body.setAttribute("id", "ar");
                                localStorage.setItem(
                                    "Display_Lang",
                                    selectMinu_ab.value
                                );
                                break;
                            case "en":
                                i18n.changeLanguage("en");
                                document.body.removeAttribute("id");
                                localStorage.setItem(
                                    "Display_Lang",
                                    selectMinu_ab.value
                                );
                                break;
                            case "ru":
                                i18n.changeLanguage("ru");
                                document.body.removeAttribute("id");

                                document.body.setAttribute("id", "ru");
                                localStorage.setItem(
                                    "Display_Lang",
                                    selectMinu_ab.value
                                );

                                break;
                        }
                    }}
                >
                    <option className="LangSelect_Option" value={"ar"}>
                        AR
                    </option>
                    <option className="LangSelect_Option" value={"en"}>
                        EN
                    </option>
                    <option className="LangSelect_Option" value={"ru"}>
                        RU
                    </option>
                </select>
            </div>
        </div>
    );
}

export default App;
